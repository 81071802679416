import React from 'react';
import { useCookies } from 'react-cookie'
import Img from '../../img/ma_vertical_logo_blue_peach.png'
import TylerImg from '../../img/tyler-logo.png'
import CentralApi from './_API/Source'

const Footer = React.memo(({ page }) => {

	const [ cookies, setCookie ] = useCookies(['signedIn']) // eslint-disable-line

  return (
    <div className='admin-footer'>
    	{CentralApi === 'https://api.mullinashley.com'
				? <img src={Img} alt='Mullin/Ashley logo' />
				: <img src={TylerImg} alt='Tyler Willis logo' style={{width: '50px', filter: 'brightness(1.5)'}}/>
    	}
		</div>
  )
})

export default Footer